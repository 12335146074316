import { ref } from '@vue/composition-api'

export default function convertImagesToBase64() {
  const base64Images = ref([])

  const createBase64Image = image => new Promise(resolve => {
    const reader = new FileReader()
    reader.onload = e => {
      resolve(e.target.result)
    }
    reader.readAsDataURL(image)
  })

  const handleImages = images => new Promise(resolve => {
    base64Images.value = []
    images.forEach(image => {
      base64Images.value.push(createBase64Image(image))
    })
    resolve(Promise.all([...base64Images.value]))
  })

  return {
    handleImages,
  }
}
