<template>
  <b-row>
    <b-col
      v-for="(image,index) in filteredImages"
      :key="index"
      md="4"
      cols="6"
      class="mb-1 image-container"
    >
      <template>
        <feather-icon
          v-if="!$attrs.disabled"
          class="x-icon"
          icon="XCircleIcon"
          size="23"
          @click="removeImage(index)"
        />
        <b-img
          :src="image"
          thumbnail
          class="uploaded-image"
        />
      </template>
    </b-col>
  </b-row>
</template>
<script>
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'ImagesBase64Preview',
  props: {
    images: { type: Array, default: () => [] },
    fileId: { type: String, default: '' },
  },
  computed: {
    filteredImages() {
      return this.images?.filter(image => image)
    },
  },
  setup() {
    const { confirmOperation } = handleAlerts()
    return {
      confirmOperation,
    }
  },
  methods: {
    removeImage(index) {
      this.confirmOperation().then(() => {
        const tempImages = [...this.images]
        tempImages.splice(index, 1)

        this.$emit('update:images', tempImages)
        this.$root.$emit(`deleteFile-${this.fileId}`, index)
      })
    },
  },
}
</script>
<style lang="scss">
.image-container {
  position: relative;
}

.x-icon {
  position: absolute;
  top:5px;
  right:20px;
  color: #fff;
  background-color: #000;
  border-radius: 50%;
  cursor: pointer;
}

.uploaded-image {
  max-height: 100%;
  width: 100%;
}
</style>
