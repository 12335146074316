<template>
  <validation-provider
    #default="validationContext"
    :name="label"
    :rules="rules"
  >
    <b-form-group
      :label-for="id"
    >
      <template #label>

        <span
          :class="{required:JSON.stringify(rules).includes('required')}"
        >
          {{ label }}
        </span>

      </template>
      <b-file
        :id="id"
        v-model="tempImages"
        accept="image/*"
        placeholder=""
        :state="getValidationState(validationContext)"
        :multiple="$attrs.multiple"
      />
      <b-form-invalid-feedback>
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import convertImagesToBase64 from '@/common/compositions/common/convertImagesToBase64'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'UploadImage',
  props: {
    rules: { type: String, default: '' },
    label: { type: String, default: '' },
    id: { type: String, default: '' },
  },
  data() {
    return {
      images: null,
    }
  },
  computed: {
    tempImages: {
      get() {
        return this.images
      },
      set(images) {
        this.images = images
        const imagesArray = this.$attrs.multiple === undefined && images !== null ? [images] : images
        this.onInput(imagesArray)
      },
    },
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()

    const {
      handleImages,
    } = convertImagesToBase64()

    const { confirmOperation } = handleAlerts()

    return {
      confirmOperation,
      handleImages,
      getValidationState,
    }
  },
  created() {
    this.$root.$on(`deleteFile-${this.id}`, this.removeInput)
  },
  beforeDestroy() {
    this.$root.$off(`deleteFile-${this.id}`, this.removeInput)
  },
  methods: {
    onInput(images) {
      if (images) {
        this.convertImage(images)
      } else {
        this.$emit('update:images', [])
        this.images = []
      }
    },
    convertImage(images) {
      this.handleImages(images).then(imagesBase64 => {
        this.updateImages(imagesBase64)
      })
    },
    updateImages(imagesBase64) {
      const strippedImagesUrl = this.removeDomainFromPath(imagesBase64)
      this.$emit('update:images', strippedImagesUrl)
    },
    removeDomainFromPath(images) {
      return images.map(image => image.replace(/https{0,1}:\/\/.*\..*\.com\/images\/.*\/public\//, ''))
    },
    removeInput(index) {
      if (Array.isArray(this.images)) {
        this.$delete(this.images, index)
      } else {
        this.images = []
      }
    },
  },
}
</script>
<style lang="scss">
.image-container {
  position: relative;
  height: 10em;
  width: 20em;
}

.x-icon {
  position: absolute;
  top:10px;
  right:1em;
  color: #fff;
  background-color: #000;
  border-radius: 50%;
  cursor: pointer;
}

.uploaded-image {
  max-height: 100%;
  width: 100%;
}
</style>
